<template>
  <AdminPageFrame>
    <PageTitle>
      {{ $route.params.id ? trans('PAGE_TITLE:PostEdit') : trans('PAGE_TITLE:PostNew') }}
    </PageTitle>
    <t-card v-if="articleEdit" class="w-full">
      <FieldWrapper class="mb-3" :label="trans('LABEL:PostTitle')">
        <TextField v-model="articleEdit.title"/>
      </FieldWrapper>
      <FieldWrapper class="mb-3" :label="trans('LABEL:PostSlug')">
        <TextField v-model="articleEdit.slug"/>
      </FieldWrapper>
      <FieldWrapper class="mb-3" :label="trans('LABEL:PostPublishedAt')">
        <t-datepicker
          dateFormat="Y-m-d H:i"
          userFormat="d F Y H:i"
          :weekStart="1"
          :lang="langLocale"
          :timepicker="true"
          v-model="articleEdit.publishedAtDate"
        />
      </FieldWrapper>
      <FieldWrapper class="mb-3">
        <SelectField v-model="articleEdit.category" :options="categoriesData"/>
      </FieldWrapper>
      <FieldWrapper class="mb-3" :label="trans('LABEL:PostFeaturedImage')">
        <div class="flex flex-row gap-3">
          <div v-if="imageUrl">
            <Picture classes="w-48 h-48 object-cover rounded" :src="imageUrl" />
          </div>
          <div>
            <input v-on:change="addFeaturedPostPicture" :value="articleEdit.imageName" type="file" />
          </div>
        </div>
      </FieldWrapper>
      <FieldWrapper class="mb-3" :label="trans('LABEL:PostExcerpt')">
        <div class="excerpt-body">
          <quill-editor
              ref="excerptEditor"
              v-model="articleEdit.excerpt"
              :options="editorOptions"
          />
        </div>
      </FieldWrapper>
      <FieldWrapper class="mb-3" :label="trans('LABEL:PostBody')">
        <div class="post-body">
          <quill-editor
              ref="myQuillEditor"
              v-model="articleEdit.body"
              :options="editorOptions"

          />
        </div>
      </FieldWrapper>
      <div class="flex flex-row justify-end">
        <Button :text="trans('BUTTON:Save')" :loading="storePostLoading" @clicked="storePostAction"/>
      </div>
    </t-card>
  </AdminPageFrame>
</template>

<script>
import AdminPageFrame from "@/views/content/admin/components/AdminPageFrame";
import {mapActions, mapGetters} from "vuex";
import TextField from "@/utils/crud/components/field-types/Text";
import PageTitle from "@/layouts/components/pages/PageTitle";
import FieldWrapper from "@/utils/crud/components/field-types/components/FieldWrapper";
import SelectField from "@/utils/crud/components/field-types/Select";
import Button from "@/utils/crud/components/Button";
import api from "@/common/services/api";
import config from "@/config"
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'

export default {
  name: "AdminPostShow",
  components: {
    AdminPageFrame,
    Button,
    FieldWrapper,
    PageTitle,
    SelectField,
    TextField,
    quillEditor,
  },
  data() {
    return {
      articleEdit: {},
      isNewArticle: false,
      imageUrl: null,
      editorOptions: {},
    }
  },
  watch: {
    '$route.params.id': {
      immediate: true,
      handler(val) {
        this.isNewArticle = val === undefined;
        if(val) {
          this.fetchAdminPostShow(val);
        }
      }
    },
    postLoaded(val) {
      if(val) {
        this.articleEdit = structuredClone(this.adminPostShow);
	      this.$set(this.articleEdit, 'category', this.categoriesData.find(el => el.id === this.articleEdit.category.id).id);
        this.imageUrl = this.articleEdit.image;
      }
    },
	  categoriesData: {
		  immediate: true,
		  handler(val) {
			  if(val) {
				  this.$set(this.articleEdit, 'category', this.categoriesData.find(el => el.slug === 'news').id);
			  }
		  }
	  },
  },
  computed: {
    ...mapGetters('admin', ['adminPostShow', 'postLoaded', 'storePostLoading']),
    ...mapGetters('dictionaries', ['categoriesData']),
    ...mapGetters('lang', ['langLocale'])
  },
  methods: {
    ...mapActions('admin', ['fetchAdminPostShow', 'storeAdminPost', 'updateAdminPost']),
    handleAttachmentChanges(event) {
      let file = event.attachment.file;

      if(file !== undefined) {
        api.setHeader();

        let data = new FormData();
        data.append('image', file);
        let path;
        api.post(`${config.API_URL}/admin/upload-image`, data).then((response) => {
          path = response;

          let attributes = {
            url: path,
            href: path
          };
          event.attachment.setAttributes(attributes);
        });
      }

    },
    addFeaturedPostPicture(event) {
      this.articleEdit.featuredImageUrl = event.target.files[0];
      this.imageUrl = URL.createObjectURL(this.articleEdit.featuredImageUrl);
    },
    storePostAction() {
      let data = new FormData();
      data.append('title', this.articleEdit.title);
      data.append('slug', this.articleEdit.slug);
      data.append('excerpt', this.articleEdit.excerpt);
      data.append('published_at', this.articleEdit.publishedAtDate);
      data.append('body', this.articleEdit.body);
      data.append('category_id', this.articleEdit.category);

      if(this.articleEdit.featuredImageUrl) {
        data.append('featuredImageUrl', this.articleEdit.featuredImageUrl);
      }

      if(this.isNewArticle) {
        this.storeAdminPost(data);
      } else {
        this.updateAdminPost({data: data, postId: this.articleEdit.id});
      }
    }
  },
  mounted() {
  }
}
</script>

<style lang="scss">
.post-body {
  .ql-editor {
      height: 440px;
  }
}
.excerpt-body {
  .ql-editor {
      height: 240px;
  }
}

.ql-video {
  width: 100%;
  height: 400px;
  padding: 16px 0;
}



</style>